
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import WebFont from 'webfontloader';

import Dropdown from '../../Components/Dropdown';

import { applyStyle, getStyleChar } from './StyleControlsUtils';
import { FontFamilyMap} from './StyleMaps';

const ControlStyle = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  height: initial;
  text-align: right;
  align-items: center;
  z-index: 1003;
  .no-edit {
    .name {
      display: inline-block;
      width: 50px;
    }
    .warning {
      color: yellow;
    }
  }
  width: 180px
`;

export const FontFamilies = [
    {
      label:'Roboto',
      value:'Roboto'
    },
    {
      label:'Roboto Mono',
      value:'RobotoMono'
    },
    {
      label:'Yusei Magic',
      value:'YuseiMagic'
    },
    {
      label:'Open Sans',
      value:'OpenSans'
    },
    {
      label:'Oswald',
      value:'Oswald'
    },
    {
      label:'Coda Caption',
      value:'CodaCaption'
    },
    {
      label:'Indie Flower',
      value:'IndieFlower'
    },
    {
      label:'Caveat',
      value:'Caveat'
    },
    {
      label:'Courgette',
      value:'Courgette'
    },
    {
      label: 'Montserrat',
      value: 'Montserrat'
    },
    {
      label: 'Lato',
      value: 'Lato'
    },
    {
      label: 'Alegreya',
      value: 'Alegreya'
    },
    {
      label: 'Poppins',
      value: 'Poppins'
    },
    {
      label: 'Raleway',
      value: 'Raleway'
    },
    {
      label: 'PT Sans',
      value: 'PTSans'
    },
    {
      label: 'Merriweather',
      value: 'Merriweather'
    },
    {
      label: 'Concert One',
      value: 'ConcertOne'
    },
    {
      label: 'Work Sans',
      value: 'WorkSans'
    },
    {
      label: 'BioRhyme',
      value: 'BioRhyme'
    },
    {
      label: 'Playfair Display',
      value: 'PlayfairDisplay'
    },
    {
      label: 'Rubik',
      value: 'Rubik'
    },
    {
      label: 'Cormorant Garamond',
      value: 'CormorantGaramond'
    },
    {
      label: 'Libre Baskerville',
      value: 'LibreBaskerville'
    },
    {
      label: 'Eczar',
      value: 'Eczar'
    },
    {
      label: 'Delicious Handrawn',
      value: 'DeliciousHandrawn'
    },
    {
      label: 'Josefin Sans',
      value: 'JosefinSans'
    },
    {
      label: 'Anton',
      value: 'Anton'
    },
    {
      label: 'Bebas Neue',
      value: 'BebasNeue'
    },
    {
      label: 'Righteous',
      value: 'Righteous'
    },
    {
      label: 'Black Ops One',
      value: 'BlackOpsOne'
    },
    {
      label: 'Markazi Text',
      value: 'MarkaziText'
    },
    {
      label: 'VT323',
      value: 'VT323'
    },
    {
      label: 'Black Han Sans',
      value: 'BlackHanSans'
    },
    {
      label: 'Comic Neue',
      value: 'ComicNeue'
    },
    {
      label: 'Average Sans',
      value: 'AverageSans'
    },
    {
      label: 'Caveat Brush',
      value: 'CaveatBrush'
    },
    {
      label: 'Syne',
      value: 'Syne'
    },
    {
      label: 'Darker Grotesque',
      value: 'DarkerGrotesque'
    },
    {
      label: 'Creepster',
      value: 'Creepster'
    },
    {
      label: 'Quicksand',
      value: 'Quicksand'
    },
    {
      label: 'Jost',
      value: 'Jost'
    },
    {
      label: 'Manrope',
      value: 'Manrope'
    },
    {
      label: 'Archivo',
      value: 'Archivo'
    },
    {
      label: 'Rubik 80s Fade',
      value: 'Rubik80sFade'
    },
];

const getOptionsStyles = () => FontFamilies.map(el => ({
  ...el,
  style: {
    'fontFamily': el.label
  }
})).sort((a, b) => ((a.label > b.label) ?  1 : -1))

const FontStyleControls = (props) => {
    const [value, setValue] = useState("Roboto");

    useEffect(() => {
        const newValue = getValue(props.editorState);
        setValue(newValue);
      }, [props.editorState]);

    const onChange = (item, name) => {
      console.log('onchange', item);
        let fontName = item.value;
        setValue(fontName );

        //TODO: move it to central controller
        WebFont.load({
            google:{
                families: [fontName]
            }
        })

        const style = 'font-' + fontName;

        let nextEditorState = applyStyle( props.block, props.editorState, style, FontFamilyMap);
        props.onChange(nextEditorState);
    };

    const getFontFromChar = (char) => {
        if (!char) {
          return 'Roboto';
        }

        const arr = char.getStyle().toArray();
        let font = 'Roboto'; //default font
        arr.forEach((style) => {
          if (style.search('font-') === 0) {
            font = style.substring(5);
          }
        });
        return font;
      };

    const getValue = (editorState) =>{
        let char = getStyleChar( editorState );
        return getFontFromChar( char );
    }

    return (
        <ControlStyle>
          <Dropdown
            name="font"
            title={getOptionsStyles(FontFamilies).find((item) => item.value == value)?.label}
            list={getOptionsStyles(FontFamilies)}
            onChange={onChange}
            styles={{headerTitle: getOptionsStyles(FontFamilies).find((item) => item.value == value)?.style}}
          />
            {/* <DropdownButton
              // onMouseDown={(e) => e.preventDefault()}
              onSelect={handleSelect}
              variant='secondary'
              title={value}>
                {FontFamilies.map((opt, i) => {
                  return <Dropdown.Item eventKey={opt}>{opt}</Dropdown.Item>;
                })}
            </DropdownButton> */}
        </ControlStyle>
    )
}

export default FontStyleControls;