import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import SwitchSmall from '../Common/Switches/SwitchSmall/SwitchSmall';
import { ReactComponent as VisibilityIcon } from '../../Assets/section-visibility.svg';
import { ReactComponent as DeleteIcon } from '../../Assets/section-delete.svg';
import { ReactComponent as DuplicateIcon } from '../../Assets/section-duplicate.svg';
import { ReactComponent as ArrowUp } from '../../Assets/arrowOverlayUp.svg';
import { ReactComponent as ArrowBottom } from '../../Assets/arrowOverlayBottom.svg';
import {
  VIEW_STATUSES,
  VIEW_TYPES,
  switchStatus,
} from '../../rx/actions/rxViewStatus';
import { eventEmiter } from '../../rx/rxState';

const ControlsStyle = styled.div.attrs((props) => ({
  style: {
    top: props.top + 10,
    left: props.left - 40,
  },
}))`
  position: absolute;
  z-index: 2001;
  width: 190px;
  display: flex;
  right: 0px;
  .borderRight{
    border-radius: 0px 4px 4px 0px;
  }
  .borderLeft{
    border-radius: 4px 0px 0px 4px;
  }
  .marginRight{
    margin-right: 7px;
  }
`;

const ControlsButtonStyle = styled.div`
  width: 40px;
  height: 40px;
  cursor:pointer;
  background-color: rgba(0, 0, 0, 1);
  display: flex;
  svg {
    fill: #d0d0d0;
  }
  :hover {
    background-color: #3E3E3E;
    svg {
      fill: #ffffff;
    }
  }
`;

const ControlsVisibilityList = styled.div`
  background-color: white;
  position: absolute;
  top: 45px;
  transform: translate(calc(-100% + 40px), 0px);
`;

const ControlsVisibilityItem = styled.div`
  z-index: 2001;
  padding: 5px;
  display: flex;
  border-top: 1px solid #eef1f4;

  .block-name {
    color: black;
    padding: 3px;
  }
  .block-switch {
    padding: 3px;
  }
`;

const ToolbarSection = (props) => {
  const [displayVisibilityControl, setDisplayVisibilityControl] =
    useState(false);
  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(0);
  const [counter, setCounter] = useState(0); //Dirty hack to make whole thing update
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    eventEmiter.subscribe({
      next: (e) => {
        if (e.type == 'view-updated') {
          update();
        }
      },
    });
    update();
  });

  const update = () => {
    setVisible(!props.block.isDragging);
    let rect = props.block.view.canvasRect;
    setTop(props.block.worldRenderBRect.y + rect.y);
    setLeft(
      props.block.worldRenderBRect.x +
        props.block.worldRenderBRect.width -
        50 +
        rect.x
    );
  };

  const handleVisiblityChange = (block, value) => {
    block.setVisible(value);
    setCounter(counter + 1);
  };

  const handleVisibilityControl = () => {
    setDisplayVisibilityControl(!displayVisibilityControl);
  };

  const visibilityList = () => {
    return (
      <ControlsVisibilityList>
        {props.visibilityBlocks.map((block) => (
          <ControlsVisibilityItem key={block.id}>
            <div className="block-name">
              {block.name ? block.name : block.type}
            </div>
            <div style={{ flexGrow: '4' }}></div>
            <div className="block-switch">
              <SwitchSmall
                value={block.isVisible}
                block={block}
                onChange={handleVisiblityChange}
              />
            </div>
          </ControlsVisibilityItem>
        ))}
      </ControlsVisibilityList>
    );
  };

  const handleDelete = (e) => {
    props.block.view.overlay.removeSelectedBlock();
    setDisplayVisibilityControl(false);
  };

  const handleDuplicate = (e) => {
    props.block.view.overlay.duplicateSelectedSection();
    setDisplayVisibilityControl(false);
  };

  const handleMoveSectionWithArrow = (direction) =>{
    props.block.view.overlay.moveSectionWithArrow(direction);
    setDisplayVisibilityControl(false);
  }
  return (
    <> 
      {visible && (
        <>
          <ControlsStyle top={top} left={left - 87}>
              <ControlsButtonStyle
                onClick={() => {
                  handleMoveSectionWithArrow('up');
                }}
                className='borderLeft'
              >
                <ArrowUp style={{ margin: 'auto' }} />
              </ControlsButtonStyle>
              <ControlsButtonStyle
                onClick={() => {
                  handleMoveSectionWithArrow('down');
                }}
                className='borderRight'
              >
                <ArrowBottom style={{ margin: 'auto' }} />
              </ControlsButtonStyle>
              

           </ControlsStyle>

          <ControlsStyle top={top} left={left}>

            {/* <ControlsButtonStyle
              onClick={(e) => {
                handleVisibilityControl(e);
              }}
              className='borderLeft'
            >
              <VisibilityIcon style={{ margin: 'auto' }} />
            </ControlsButtonStyle> */}

            <ControlsButtonStyle onClick={handleDuplicate} className='borderLeft'>
              <DuplicateIcon style={{ margin: 'auto' }} />
            </ControlsButtonStyle>

            <ControlsButtonStyle onClick={handleDelete} className='borderRight'>
              <DeleteIcon style={{ margin: 'auto' }} />
            </ControlsButtonStyle>

            {displayVisibilityControl ? visibilityList() : null}
          </ControlsStyle>
        
        </>
      )}
    </>
  );
};

export default ToolbarSection;
