import React, { useState, useEffect, useRef } from 'react';
import { AI_COPYRIGHT_URL } from 'Constants';

const WsTestPage = () => {
  const [request, setRequest] = useState('');
  const [status, setStatus] = useState('disconnected');
  const socket = useRef<WebSocket | null>(null);
  const [aiResponse, setAiResponse] = useState('');
  const [response, setResponse] = useState('');

  useEffect(() => {
    socket.current = new WebSocket(AI_COPYRIGHT_URL);
    socket.current.addEventListener("open", (event) => {
      setStatus('connected');
      if (!socket.current) {
        console.error('no connection');
        return;
      }
      socket.current.addEventListener('error', (event) => {
        console.error('WS Error', event)
        setStatus('connection error');
      });
      socket.current.addEventListener('close', (event) => {
        console.log('closed', event)
        setStatus('closed');
      });
      socket.current.addEventListener('message', (event) => {
        const data = JSON.parse(event.data);
        if (data.text !== undefined) {
          setAiResponse(data.text);
        }
      })
    })
  }, []);

  useEffect(() => {
    setResponse(response + aiResponse);
  }, [aiResponse])

  const handleSendMessage = () => {
    setAiResponse('\n');
    socket.current?.send(JSON.stringify({ prompt: request }));
    setRequest('');
  }

  return (
    <div>
      <h1>Ws test page</h1>
      <h5>{status}</h5>
      <textarea placeholder='Request' value={request} onChange={(e) => setRequest(e.target.value)}></textarea>
      <div>
        <button onClick={() => handleSendMessage()}>
          Send
        </button>
      </div>
      <h3>Response:</h3>
      <pre>
        {response}
      </pre>
    </div>
  )
}


export default WsTestPage;