import React, { Fragment } from 'react';
import {Block, BlockStyle} from './Block';
import styled from 'styled-components';

export const BlockRowStyle = styled(BlockStyle)`
    display: grid;
    grid-template-columns: repeat(${props=>props.columns}, 1fr);

`

export class BlockRowProxy extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            // url: props.content,
        }
    }


    componentDidMount(){
        this.props.reference.current.style.cssText = this.props.styleText;
    }

    componentDidUpdate(prevProps){
        if(prevProps.styleText !== this.props.styleText){
            this.props.reference.current.style.cssText = this.props.styleText;
        }
    }



    render(){

        return <BlockRowStyle
            id={this.props.id}
            key={this.props.id}
            ref={this.props.reference}
            columns={this.props.columns}
            className={this.props.className + 's-row'}
            >
                {this.props.children}
            </BlockRowStyle>
        }
}

export class BlockRow extends Block{
    constructor(props){
        super(props);
        this.type = 'Row';
        this.columns = 0;

        this.renderGrid = false;
    }



    renderView(){
        const size = this.view.getViewSize();
        const span = this.getGridSpan( size );
        this.columns = span.length;

        return  (
        <Fragment key={this.id}>
        { this.isVisible ?
         <BlockRowProxy
            id={this.id}
            key={this.id}
            view={this.view}
            reference={this.ref}
            styleText={this.style()}
            className={this.className}
            columns={this.columns}
            >
                {this.children.length ? 
                    this.children.map((child)=>{
                    return child.renderView();
                    })
                    :
                    <div style={{
                        minHeight:'40px', 
                        gridColumnEnd: 'inherit',
                        borderRadius: '10px',
                        border: '1px solid #73AD21'
                    }}></div>
                }
            </BlockRowProxy>
            :
            null}
        </Fragment>
            )
    }

    columnWidthAndGap(){
        let gap = 40;
        if(this.ref.current){
            const style = getComputedStyle(this.ref.current);
            gap = parseInt(style.columnGap)
        }

        const rect = this.worldRenderBRect;
        return {width: (rect.width+gap)/(this.columns) - gap, gap:gap};
    }

    renderOverlay(ctx){
        if(this.renderGrid){
            let rect = this.worldRenderBRect;
            const {width, gap} = this.columnWidthAndGap();
            for( var i=0; i<this.columns; i++){
                
                const r = {
                    x: rect.x + i*(width+gap),
                    y: rect.y + 1,
                    width: width,
                    height: rect.height - 2
                }

                ctx.fillStyle = 'rgba(0.2,0.2,0.2,0.05)';
                ctx.strokeStyle = 'rgba(255,255,255,0.4)';
                ctx.lineWidth = 1;
                ctx.fillRect(
                    r.x,
                    r.y,
                    r.width,
                    r.height);
    
            }
            ctx.setLineDash([2, 4]);
            ctx.strokeRect(
                rect.x,
                rect.y,
                rect.width,
                rect.height 
            )
            ctx.setLineDash([]);


            for (let child of this.children) {
                child.renderOverlay(ctx);
            }
        }
        else{
            super.renderOverlay(ctx);
        }
    }
}
