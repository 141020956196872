import React, { Fragment } from 'react';
import {Block} from './Block';
import styled from 'styled-components';

export const BlockColumnStyle = styled.div`
    // pointer-events: none;
`

export class BlockColumnProxy extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            url: props.content,
        }
    }


    componentDidMount(){
        this.props.reference.current.style.cssText = this.props.styleText;
    }

    componentDidUpdate(prevProps){
        if(prevProps.styleText !== this.props.styleText){
            this.props.reference.current.style.cssText = this.props.styleText;
        }
    }



    render(){

        return <BlockColumnStyle
            id={this.props.id}
            key={this.props.id}
            ref={this.props.reference}
            style={this.props.style}
            className={this.props.className}
            >
                {this.props.children}
            </BlockColumnStyle>
        }
}

export class BlockColumn extends Block{
    constructor(props){
        super(props);

        this.type = 'Column';
   }



    renderView(){
        // console.log('this.style()', this.style())
        return  (
        <Fragment key={this.id}>
        { this.isVisible ?
         <BlockColumnProxy
            id={this.id}
            key={this.id}
            view={this.view}
            reference={this.ref}
            styleText={this.style()}
            className={this.className}

            >
                {this.children.length ? 
                    this.children.map((child)=>{
                    return child.renderView();
                    })
                    :
                    <div>EMPTY</div>
                }
            </BlockColumnProxy>
            :
            null}
        </Fragment>
            )
    }

    renderOverlay(ctx){
        super.renderOverlay(ctx);
        // const rect = this.worldRenderBRect;
        // const r = {
        //     x: rect.x + 1,
        //     y: rect.y + 1,
        //     width: rect.width - 2,
        //     height: rect.height - 2
        // }

        //         ctx.fillStyle = 'rgba(255.0,0.2,0.2,0.3)';
        //         ctx.lineWidth = 2;
        //         ctx.fillRect(
        //             r.x,
        //             r.y,
        //             r.width,
        //             r.height);
    
    }
}
