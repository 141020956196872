import React, { Fragment } from 'react';
import styled from 'styled-components';
import {Block, BlockStyle} from './Block';
import StoreController from '../../StoreController';

const BlockCustomStyle = styled(BlockStyle)`

`;

class BlockCustomProxy extends React.Component {
    componentDidMount(){
        this.props.reference.current.style.cssText = this.props.styleText;
      }
    render(){
        return <BlockCustomStyle
            id={this.props.id}
            key={this.props.id}
            ref={this.props.reference}
            className={this.props.className}
            >

                <textarea
                    style={{width: '100%', height: '100%', fontFamily: 'monospace'}}
                    onChange={this.props.onChange}
                    spellCheck={false}>
                    {this.props.code}
                </textarea>

            </BlockCustomStyle>
        }
}

export class BlockCustom extends Block{
    constructor(props){
        super(props);
        this.type = 'Custom';
        this.code = "";
    }

    pack(){
        let data = super.pack();
        //look for <script> and replace it 
        var processed = this.code.replaceAll('<script>', '<scr>')
        var processed = processed.replaceAll('</script>', '</scr>')
        data['code'] = processed;
        return data;
    }


    unpack(data){
        super.unpack(data);
        //look for <scr> and replace it back to <script>
        var processed = data['code'].replaceAll('<scr>', '<script>')
        var processed = processed.replaceAll('</scr>', '</script>')
        this.code = processed;
    }
    onChange(event){
        this.code = event.target.value;
    }
    renderView(){
        let store = StoreController.instance();
        return (
          <Fragment key={this.id}>
            {store.liveMode ?
            <div
                style={this.styleDict()}
                id={this.id}
                key={this.id}
                reference={this.ref}
                dangerouslySetInnerHTML={{__html: this.code}}>

            </div>
            :
            <BlockCustomProxy
                id={this.id}
                key={this.id}
                reference={this.ref}
                styleText={this.style()}
                code={this.code}
                onChange={e=>this.onChange(e)}
                className={this.className}

                >

            </BlockCustomProxy>

            }
          </Fragment>
        )
    }
}