import React, { useEffect, useState, useReducer } from 'react';
import { eventEmiter, rxSelectedBlocks } from '../../rx/rxState';
import TextEditorToolbar from '../TextEditor/TextEditorToolbar';
import styled from 'styled-components';
import ColorSelector from '../ColorSelector';
import AttributeComponent from '../../Attributes/AttributeComponent';

import ToolbarSection from './ToolbarSection';

const ToolbarStyle = styled.div`
    color: black;
`
const ToolbarBlocks = (props) => {
    let selectedBlockSub = null;
    const [block, setBlock] = useState( null );
    const [counter, setCounter] = useState( 0 ); //Forced Update counter
    const forcedUpdate = () => setCounter((count) => count + 1);

    useEffect(() => {
        if (!selectedBlockSub) {
            selectedBlockSub = rxSelectedBlocks.subscribe({
                next: (v) => {
                    if (v.length > 0) {
                        setBlock( v[0] );
                    } else {
                        setBlock( null );
                    }
                }
            })
        }

        return () => {
            selectedBlockSub.unsubscribe();
        }
    })

    const onColorChange = (color)=>{
        block.color = color;
        eventEmiter.next({
            type: 'update-view'
        });
        forcedUpdate()
    };

    const onAttributeChanged = (attrName, newValue) => {
        block[attrName].value = newValue;

        // block.update();
        // console.log();
        //FORCED UPDATE
        forcedUpdate()
    }





    const getToolbar = (block) => {
        if(block){
            let attributes = block.attributes;
            return(
                
                <div style={{display:'flex'}}>
                    <TextEditorToolbar
                        block={block}
                    />
                    {attributes.map((attr)=>{
                        if(attr.visible !== undefined && attr.visible === false){
                            return null;
                        }
                        return <AttributeComponent
                                attribute={attr}
                                block={block}
                                key={attr.id}
                                onChange={onAttributeChanged}
                            />
                    })}

            </div>
            )

        }

        return null;
    }
    const filterChildren = ( filteredChildren, block ) => {
        for( let child of block.children){
            if((child.type !== 'Column' && child.type !== 'Placeholder' && child.isSelectable === true && child.canHide === undefined)
            || child.canHide === true ){
                filteredChildren.push( child );
            }
            if(child.type !== 'Button'){
                filterChildren(filteredChildren, child);
            }
        }
    }

    const getToolbarSection = (block)=>{
        if(!block || block.type !== 'Section'){
            return null;
        }

        let visibilityBlocks = [];
        filterChildren( visibilityBlocks, block);

        return (
        <ToolbarSection
            block={block}
            visibilityBlocks={visibilityBlocks}
                />
        )
    }

    return (
        <>
            { getToolbarSection( block) }
            <ToolbarStyle>
                {getToolbar( block )}
            </ToolbarStyle>

        </>
    )
};
export default ToolbarBlocks;
