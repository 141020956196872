import React, { useState, useEffect, useRef } from 'react';
import styles from './StartVideoBlock.module.scss';
import playSvg from '../../Assets/icons/play.svg';

interface StartVideoBlockProps {}

const StartVideoBlock: React.FC<StartVideoBlockProps> = () => {
  const videoRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);
  const [isPlayed, setIsPlayed] = useState(false);
  const [isAnimatingOut, setIsAnimatingOut] = useState(false);

  useEffect(() => {
    const videoHasAlreadyBeenPlayed = localStorage.getItem(
      'pageEditorVideoPlayed'
    );
    if (videoHasAlreadyBeenPlayed !== null) {
      const parsedVideoHasAlreadyBeenPlayed = JSON.parse(
        videoHasAlreadyBeenPlayed
      );
      if (!parsedVideoHasAlreadyBeenPlayed) {
        setIsOpen(true);
      }
    } else {
      setIsOpen(true);
    }
  }, []);

  const handlePlay = () => {
    if (videoRef.current) {
      const videoElement = videoRef.current as HTMLVideoElement;
      setIsPlayed(true);
      videoElement.play();
    }
  };

  const handlePause = () => {
    if (videoRef.current) {
      const videoElement = videoRef.current as HTMLVideoElement;
      videoElement.pause();
    }
  };

  const handleClose = () => {
    setIsAnimatingOut(true);
    localStorage.setItem('pageEditorVideoPlayed', JSON.stringify(true));
    handlePause();
    setTimeout(() => {
      setIsOpen(false);
      setIsAnimatingOut(false);
    }, 1000);
  };

  if (!isOpen) return null;

  return (
    <div
      className={`${styles.startVideoBlockWrapper} ${
        isAnimatingOut ? styles.fadeOut : styles.fadeIn
      }`}
    >
      <div className={styles.startVideoBlockContent}>
        <div className={styles.startVideoBlock}>
          <video ref={videoRef} onEnded={() => handleClose()}>
            <source src={'https://s3.us-east-2.amazonaws.com/cdn.autofunnel.ai/Intrudiction+to+the+page+editor.mp4'} />
          </video>
          {!isPlayed && (
            <div className={styles.playBtn} onClick={() => handlePlay()}>
              <img src={playSvg} alt="play button" />
            </div>
          )}
        </div>
        <div className={styles.skipVideoBtnBlock}>
          <button className={styles.skipVideoBtn} onClick={() => handleClose()}>
            Get Started
          </button>
        </div>
      </div>
    </div>
  );
};

export default StartVideoBlock;
