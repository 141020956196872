import React from 'react';
import StoreController from '../../StoreController';
import styled from 'styled-components';
import { Block, BlockStyle } from './Block';
import ShiftClock from '../../Components/ShiftClock';
import {rxPageId} from '../../rx/rxState';

const TimerStyle = styled.div`
  padding: 2px;
  align-items: center;
  text-align: center;
  background: transparent;
  display: flex;
  margin-left: auto;
  width: 100%;
  color: ${(props) => props.color};  
`;

const ValueStyle = styled.div`
  font-size: ${(props) => props.size == 'small'? '24px' : props.size == 'large' ? '68px': '45px'}; //TODO need to make it more readable
  width: ${(props) => props.size == 'small'? '35px' : props.size == 'large'? '100px' : '70px'}; //TODO need to make it more readable
  background: ${(props) => props.color};
  font-weight: 500;
  border-radius: 5px;
  color: white;
  margin: 3px;
`;

const LabelStyle = styled.div`
  font-size: 12px;
  margin-top: 0px;
`;

const BlockTimerStyle = styled(BlockStyle)`
`;



export class BlockTimerProxy extends React.Component {
  constructor(props) {
    super(props);
    this.timerRef = React.createRef();
    this.targetTime = 0;
    this.timeInterval = null;
    this.key = `timer-${this.props.id}-${rxPageId.getValue()}-targetTime`;
    this.onTimeUpdate = this.onTimeUpdate.bind(this);
  }

  onTimeUpdate() {
    this.setState({});
    
  }
  

  componentDidUpdate(prevProps) {
    if(prevProps.styleText !== this.props.styleText){
      this.props.reference.current.style.cssText = this.props.styleText;
    }

    let visible =
      this.timerRef.current &&
      this.timerRef.current.getBoundingClientRect().height !== 0;

    let store = StoreController.instance();
    if(!store.liveMode){
      this.targetTime = null;
    }
    if (visible ) {
      if(store.productionMode && this.props.individualized){
        let savedTime = localStorage.getItem(this.key);
        if (savedTime !== null) {
          this.targetTime = savedTime;
          return;
        }
      }
    }
  }
  componentDidMount() {
    let store = StoreController.instance();
    if (store.liveMode) {
      this.timeInterval = setInterval(this.onTimeUpdate, 1000);
    }
    this.props.reference.current.style.cssText = this.props.styleText;  
    this.targetTime = this.props.targetTime;
    
    let time = localStorage.getItem(this.key);
    if(store.productionMode && this.props.individualized && !time){
      localStorage.setItem(this.key, this.targetTime);
    }
  }

  componentWillUnmount() {
    clearInterval(this.timeInterval);
  }

  render() {
    if (!StoreController.instance().liveMode) {
      this.targetTime = this.props.targetTime;
    }

    let hours = '00';
    let minutes = '00';
    let seconds = '00';
    let days = '00';
    if (this.targetTime > 0) {
      let delta = this.targetTime; 
      // if (StoreController.instance().liveMode) {
        delta = this.targetTime - Date.now();
      // }
      delta = Math.floor(delta / 1000);
      if (delta > 0) {
        days = Math.floor(delta / 24 / 60 / 60);
        hours = Math.floor(delta / 60 / 60) - days * 24;
        minutes = Math.floor(delta / 60) - hours * 60 - days * 24 * 60;
        seconds = delta % 60;

        days = days.toString().padStart(2, '0');
        hours = hours.toString().padStart(2, '0');
        minutes = minutes.toString().padStart(2, '0');
        seconds = seconds.toString().padStart(2, '0');
      }
      if (
        delta <= 0 &&
        StoreController.instance().liveMode &&
        this.props.target !== ''
      ) {
        // sendEvent(this.props.target.value).then((result) => {
          window.open('https://'+this.props.target, '_top');
        // });
      }
    }

    let height = 65;
    if(this.props.size === 'small'){
      height = 35;
    }
    else if(this.props.size == 'large'){
      height = 95;
    }
    return (
      <BlockTimerStyle id={this.props.id} ref={this.props.reference} className={this.props.className}>
        <TimerStyle ref={this.timerRef} color={this.props.color}>
          <div style={{ flexGrow: 1 }}></div>

          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div>
              <ValueStyle color={this.props.color} size={this.props.size}>
                <ShiftClock value={days} height={height} size={this.props.size}/>
              </ValueStyle>
              {this.props.size !== 'small' && <LabelStyle>DAYS</LabelStyle>}
            </div>
            <div>
              <ValueStyle color={this.props.color} size={this.props.size}>
                <ShiftClock value={hours} height={height} size={this.props.size}/>
              </ValueStyle>
              {this.props.size !== 'small' && <LabelStyle>HOURS</LabelStyle>}
            </div>
            <div>
              <ValueStyle color={this.props.color} size={this.props.size}>
                <ShiftClock value={minutes} height={height} size={this.props.size}/>
              </ValueStyle>
              {this.props.size !== 'small' && <LabelStyle>MINUTES</LabelStyle>}
            </div>
            <div>
              <ValueStyle color={this.props.color} size={this.props.size}>
                <ShiftClock value={seconds} height={height} size={this.props.size}/>
              </ValueStyle>
              {this.props.size !== 'small' && <LabelStyle>SECONDS</LabelStyle>}
            </div>
          </div>

          <div style={{ flexGrow: 1 }}></div>
        </TimerStyle>
      </BlockTimerStyle>
    );
  }
}

export class BlockTimer extends Block {
  constructor(props) {
    super(props);

    this.type = 'Timer';

    let attr = {
      id: 'color',
      displayName: 'Color',
      value: '#000000',
      type: 'AttributeColor'
    }
    this.addAttribute( attr );

    attr = {
      id: 'size',
      displayName: 'Size',
      value: 'medium',
      type: 'AttributeSwitch',
      options: [{
              label: 'S',
              value: 'small'
          }, 
          {
              label: 'M',
              value: 'medium'
          },
          {
            label: 'L',
            value: 'large'
        }],
    }
    this.addAttribute( attr );


    attr = {
      id: 'timerType',
      displayName: 'Type',
      value: 'Countdown',
      type: 'AttributeDropdown',
      options: [{
              label: 'Countdown',
              value: 'Countdown'
          }, 
          {
              label: 'Timer',
              value: 'Timer'
          }],
    }
    this.addAttribute( attr );

    attr = {
      id: 'finalDate',
      displayName: 'Final Date',
      value: new Date(),
      type: 'AttributeDate',
    }
    this.addAttribute( attr );

    attr = {
      id: 'individualized',
      displayName: 'Individualized',
      value: true,
      type: 'AttributeBool',
      visible: false
    }
    this.addAttribute( attr );

    attr = {
      id: 'timerLength',
      displayName: 'Length',
      value: null,
      type: 'AttributeTime',
      pattern: 'date',
      visible: false
    }
    this.addAttribute( attr );

    attr = {
      id: 'url',
      displayName: '',
      value: '',
      width: 200,
      prefix: 'End URL:',
      type: 'AttributeString',
      visible: true
  }
  this.addAttribute( attr );
  }

  update(){
    super.update();
    if(this.timerType.value === 'Timer'){
      this.individualized.visible = true;
      this.timerLength.visible = true; 
      this.finalDate.visible = false;
    }
    else{
      this.individualized.visible = false;
      this.timerLength.visible = false; 
      this.finalDate.visible = true;
    }
  }

  pack(){
    let data = super.pack();
    data['finalDate'] = this.finalDate.value.valueOf();
    return data;
  }
  unpack(data){
    super.unpack(data);
    if(this.finalDate.value){
      this.finalDate.value = new Date(this.finalDate.value);
    }
  }


  renderView() {
    let targetTime = 0;

    //COUNT DOWN MODE
    if(this.timerType.value === 'Countdown'){
      if(this.finalDate.value){
        targetTime = this.finalDate.value.valueOf();
      }
      else{
        targetTime = 0;
      }
    }
    //TIMER MODE
    else{
      if(this.timerLength.value){
        let components = this.timerLength.value.split(':');
        if(components.length == 3 || components.length == 4){
          let time = parseInt(components[0]) * 24 * 60 * 60 * 1000; //days
          time += parseInt(components[1]) * 60 * 60 * 1000; //hours
          time += parseInt(components[2]) * 60 * 1000; //minutes
          
          if(components.length == 4){
            time+= parseInt(components[3]) * 1000 + 1000; // seconds (plus one extra second)
          }
          targetTime = time + Date.now();
        }
      }

      else{
        console.log('warning: wrong time format for timer');
      }
    }

    return (
      <BlockTimerProxy
        id={this.id}
        key={this.id}
        onChange={this.onChange}
        reference={this.ref}
        styleText={this.style()}
        color={this.color.value}
        size={this.size.value}
        individualized={this.individualized.value}
        timerLength={this.timerLength.value}
        targetTime={targetTime}
        target={this.url.value}
        className={this.className}
      ></BlockTimerProxy>
    );
  }
}
