import React from 'react';
import s from './TextArea.module.scss';

interface IProps {
  value: string;
  onChange: (newValue: string) => void;
  label: string;
  hasErrors?: boolean;
  height?: number;
}

const TextArea = (props: IProps) => {
  return (
    <div
      className={s.textareaWrapper}
      style={props.height ? { height: `${props.height}px` } : {}}
    >
      <textarea
        placeholder={props.label}
        value={props.value}
        onChange={(e) => props.onChange(e.target.value)}
        className={s.textArea + ' ' + (props.hasErrors ? s.error : '')}
        style={props.height ? { resize: 'none' } : {}}
      />
    </div>
  );
};

export default TextArea;
