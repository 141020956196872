import React, { useState, useRef, useEffect } from 'react';
import ContentEditableDiv from '../ContentEditableDiv/ContentEditableDiv';
import { IBookChapter } from '../types';
import { ReactComponent as ThreeDots } from '../../../Assets/threeDots.svg';
import { ReactComponent as ChapterDelete } from '../../../Assets/chapterDelete.svg';
import { ReactComponent as ChapterAdd } from '../../../Assets/chapterAdd.svg';

import { ReactComponent as ChapterDragAndDrop } from '../../../Assets/chapterDragAndDrop.svg';
import arrow from '../../../Assets/icons/arrow.svg';
import pencil from '../../../Assets/pencil.svg';
import checkMark from '../../../Assets/whiteCheckMark.svg';

import s from './ChapterItem.module.scss';
import DropdownBox from 'Components/Common/dropdownBox/DropdownBox';
import clsx from 'clsx';
import TextArea from '../TextArea/TextArea';
import Button from 'Components/Common/Button/Button';
import Spinner from 'Components/Common/Spinner/Spinner';
import { rxActiveChapterIndex } from 'rx/rxState';

interface IProps {
  chapter: IBookChapter;
  index: number;
  onChapterTitleEditedProps: (value: string, index: number) => void;
  activeChapter: number;
  setActiveChapter: (index: number) => void;
  deleteChapter: (index: number) => void;
  onChapterAdd: (indexBefore: number, action: 'add' | 'insert') => void;
  loadingAddChapter: boolean;
  onNoteAdd: (chapterIndex: number, noteText: string) => void;
  isBookTitleActive: boolean;
  setIsBookTitleActive: (arg: boolean) => void;
}

function ChapterItem({
  chapter,
  index,
  onChapterTitleEditedProps,
  activeChapter,
  setActiveChapter,
  deleteChapter,
  onChapterAdd,
  loadingAddChapter,
  onNoteAdd,
  isBookTitleActive,
  setIsBookTitleActive,
}: IProps) {
  const [chapterTitleInput, setChapterTitleInput] = useState<string>(
    chapter.title
  );
  const [isDotsMenuOpen, setIsDotsMenuOpen] = useState<boolean>(false);
  const [noteTextAreaInput, setNoteTextAreaInput] = useState<string>(
    chapter.comments[0]
  );
  const [isNoteTextAreaOpen, setIsNoteTextAreaOpen] = useState<boolean>(false);
  const [isHasNote, setIsHasNote] = useState<boolean>(false);

  const refDotsMenu = useRef(null);

  const onChapterTitleEdited = (value: string) => {
    onChapterTitleEditedProps(value, index);
  };

  const handleChangeNote = () => {
    if (noteTextAreaInput) {
      onNoteAdd(index, noteTextAreaInput.trim());
      setIsNoteTextAreaOpen(false);
    } else {
      onNoteAdd(index, '');
      setIsNoteTextAreaOpen(false);
    }
  };

  useEffect(() => {
    if (chapter.comments[0] && chapter.comments[0].trim().length > 0) {
      setIsHasNote(true);
    } else {
      setIsHasNote(false);
    }
  }, [chapter.comments]);

  useEffect(() => {
    if (isNoteTextAreaOpen && activeChapter != index) {
      handleChangeNote();
    }
  }, [isNoteTextAreaOpen, activeChapter]);

  const onChapterClick = () => {
      setActiveChapter(index);
      setIsBookTitleActive(false);
  };

  return (
    <div
      className={clsx(s.chapterItemWrapper, {
        [s.chapterItemWrapperActive]: index === activeChapter,
      })}
    >
      <div
        className={s.chapterItem}
        onClick={() => onChapterClick()}
      >
        <div
          className={s.chapterIndexCircle}
        >
          {index + 1}
        </div>
        <div
        >
          <ContentEditableDiv
            name={chapterTitleInput}
            fontSize={16}
            lineHeight={22}
            fontWeight={500}
            width={270}
            height={40}
            onChange={setChapterTitleInput}
            onEdit={onChapterTitleEdited}
            onClick={() => onChapterClick()}
            isMultiline={true}
          />
        </div>
        <ChapterDragAndDrop
          className={s.chapterDragAndDropSvg}
        />
          <div
            className={s.noteChangeButton}
            onClick={() => setIsNoteTextAreaOpen(!isNoteTextAreaOpen)}
          >
            {isHasNote ? (
              <>
                Click to Edit Note
                <img src={pencil} alt="pencil" />
              </>
            ) : (
              <>
                Click to add a Note
                <img src={arrow} alt="arrow" />
              </>
            )}
          </div>
        <ThreeDots
          className={s.threeDotsSvg}
          ref={refDotsMenu}
          onClick={() => setIsDotsMenuOpen(true)}
        />
        <DropdownBox
          isOpen={isDotsMenuOpen}
          onClose={() => setIsDotsMenuOpen(false)}
          pointerEvents={true}
          componentRef={refDotsMenu}
          height={70}
        >
          <div className={s.dotsMenuBlock}>
              <>
                <div
                  className={
                    loadingAddChapter 
                      ? s.cursorDisabled
                      : ''
                  }
                  onClick={
                    loadingAddChapter
                      ? () => {}
                      : () => {
                          onChapterAdd(index, 'insert');
                          setIsDotsMenuOpen(false);
                        }
                  }
                >
                  <ChapterAdd /> Add New Above
                </div>
                <div
                  className={
                    loadingAddChapter 
                      ? s.cursorDisabled
                      : ''
                  }
                  onClick={
                    loadingAddChapter 
                      ? () => {}
                      : () => {
                          onChapterAdd(index + 1, 'insert');
                          setIsDotsMenuOpen(false);
                        }
                  }
                >
                  <ChapterAdd /> Add New Below
                </div>
              </>
            <div
              className={
                loadingAddChapter 
                  ? s.cursorDisabled
                  : ''
              }
              onClick={
                loadingAddChapter
                  ? () => {}
                  : () => {
                      deleteChapter(index);
                      setIsDotsMenuOpen(false);
                    }
              }
            >
              <ChapterDelete /> Delete
            </div>
          </div>
        </DropdownBox>
      </div>
      {isNoteTextAreaOpen &&  (
        <div className={s.textAreaBlock}>
          <TextArea
            label=""
            onChange={setNoteTextAreaInput}
            value={noteTextAreaInput}
            height={90}
          />
          <Button
            size="regular"
            onClick={() => handleChangeNote()}
            borderRadius={'25px'}
          >
            Add Note <img src={checkMark} alt="check mark" />
          </Button>
        </div>
      )}
      {!isNoteTextAreaOpen && isHasNote  && (
        <div className={s.noteBlock}>{chapter.comments[0]}</div>
      )}
    </div>
  );
}

export default ChapterItem;
