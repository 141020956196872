import React, { useState, useEffect } from 'react';

import style from './fakeLoading.module.scss';
const textForLoading = [
  'Learning about your product...',
  'Designing site structure...',
  'Creating page headline...',
  'Creating your sales copy...', 
  'Making products images...',
  'Choosing final layout...',
  'Adding cart support...',
  'Optimizing lead pages...',
  'Building upsell page support...',
  'Compiling sales funnel...',
  'Finalizing... Your page will be ready in a moment.',
];
const FakeLoading = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [progress, setProgress] = useState(0);
  const [progressText, setProgressText] = useState(
    'Learning about your product...'
  );
  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress < 100) {
          return prevProgress + 1;
        }
      });
    }, 350);

    const timer = setTimeout(() => {
      // setIsLoading(false);
    }, 50000);

    return () => {
      clearInterval(interval);
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    if (progress >= 0 && progress < 10) {
      setProgressText(textForLoading[0]);
    }
    if (progress >= 10 && progress < 20) {
      setProgressText(textForLoading[1]);
    }
    if (progress > 20 && progress < 30) {
      setProgressText(textForLoading[2]);
    }
    if (progress > 30 && progress < 40) {
      setProgressText(textForLoading[3]);
    }
    if (progress > 40 && progress < 50) {
      setProgressText(textForLoading[4]);
    }
    if (progress > 50 && progress < 60) {
      setProgressText(textForLoading[5]);
    }
    if (progress > 60 && progress < 70) {
      setProgressText(textForLoading[6]);
    }
    if (progress > 70 && progress < 80) {
      setProgressText(textForLoading[7]);
    }
    if (progress > 80 && progress < 90) {
      setProgressText(textForLoading[8]);
    }
    if (progress > 90 && progress < 100) {
      setProgressText(textForLoading[9]);
    }
    if (progress === 100) {
      setProgressText(textForLoading[10]);
    }
  }, [progress]);
  return isLoading ? (
    <div className={style.loadingIndicatorWrapper}>
      <div className={style.loadingIndicatorText}>{progressText}</div>
      <div className={style.loadingIndicator}>
        <div
          className={style.loadingIndicatorBar}
          style={{ width: `${progress}%` }}
        />
      </div>
    </div>
  ) : null;
};

export default FakeLoading;
