import { BehaviorSubject, Subject } from 'rxjs';

export const rxBlocks = new BehaviorSubject([]);
export const rxNavigation = new BehaviorSubject({});
export const rxPages = new BehaviorSubject({});
export const rxIsLoading = new BehaviorSubject('loading...');
export const rxCurrentPage = new BehaviorSubject('');
export const rxError = new BehaviorSubject(false);
export const rxAlerts = new BehaviorSubject([]);
export const rxBlocksFetched = new BehaviorSubject(false);
export const rxSelectedBlocks = new BehaviorSubject([]);

export const rxUserName = new BehaviorSubject('');
export const rxPageId = new BehaviorSubject('');
export const rxEditMode = new BehaviorSubject(false);
export const rxProjectName = new BehaviorSubject('');
export const rxPageName = new BehaviorSubject('');
export const rxViewWidth = new BehaviorSubject('Responsive');
export const rxViewZoom = new BehaviorSubject(1);
export const rxAccentColor = new BehaviorSubject('#5cc2fc');
export const rxProducts = new BehaviorSubject([]);
export const rxProjectId = new BehaviorSubject('');
export const rxStripeKey = new BehaviorSubject('');

export const rxDragData = new BehaviorSubject({});
export const rxCreationData = new BehaviorSubject({});

export const rxHistory = new BehaviorSubject([]);
export const rxHistoryStage = new BehaviorSubject(-1);

export const eventEmiter = new Subject();
export const rxSideMenu = new BehaviorSubject([]);
export const rxSections = new BehaviorSubject([]);

export const rxBlockIndexForSectionsPopup = new BehaviorSubject(-1);
export const rxManipulatorHasBeenClicked = new BehaviorSubject(false);

export const rxExitModalEnabled = new BehaviorSubject(false);
export const rxShowExitModal = new BehaviorSubject(false);
export const rxExitModalData = new BehaviorSubject({});

export const rxPageSaved = new BehaviorSubject(false);
export const rxCustomScripts = new BehaviorSubject();
export const rxTitle = new BehaviorSubject();

export const rxNewChapterIndex = new BehaviorSubject(null);
export const rxChapterIndexForDelete = new BehaviorSubject(null);
export const rxBookNeedSave = new BehaviorSubject(false);
export const rxBookDataForSave = new BehaviorSubject(null);
export const rxActiveChapterIndex = new BehaviorSubject(0);
export const rxChapterRenameData = new BehaviorSubject(null);
export const rxSelectBookImg = new BehaviorSubject('');


